export class PaginationParams {
  page = 1;
  pageSize = 10;
}

export class QueryParams extends PaginationParams {
  ordering?: string;
  search?: string;
  [key: string]: string | number;
}

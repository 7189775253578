import { PaginationResponse } from '../types/classes/pagination-response.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from '../types/classes/query-params.class';
import { filter, map, switchMap, tap } from 'rxjs/operators';

export abstract class CrudPaginationService<T extends { id?: number }> {
  protected values = new BehaviorSubject<T>(null);
  protected queryParams = new BehaviorSubject<QueryParams>(new QueryParams());
  protected loading = new BehaviorSubject<boolean>(null);
  protected count = new BehaviorSubject<number>(null);

  protected constructor(protected _http: HttpClient) {}

  abstract get url(): string;

  refresh() {
    this.values = new BehaviorSubject<T>(null);
    this.queryParams = new BehaviorSubject<QueryParams>(new QueryParams());
    this.loading = new BehaviorSubject<boolean>(null);
    this.count = new BehaviorSubject<number>(null);
  }

  changeParams(queryParams: QueryParams) {
    this.queryParams.next({ ...this.queryParams.value, ...queryParams });
  }

  subscribeValues$(): Observable<T[]> {
    return this.queryParams.pipe(
      filter(value => !!value),
      tap(() => this.loading.next(true)),
      switchMap(params => this.list(params)),
      tap(() => this.loading.next(false)),
      tap((response: PaginationResponse<T>) => this.count.next(response.count)),
      map((response: PaginationResponse<T>) => response.results)
    );
  }

  list(queryParams: QueryParams): Observable<PaginationResponse<T>> {
    const params = this.getParams(queryParams);
    return this._http.get<PaginationResponse<T>>(`${this.url}`, { params });
  }

  get(valueId: number): Observable<T> {
    return this._http.get<T>(`${this.url}/${valueId}`);
  }

  add(value: T): Observable<T> {
    return this._http.post<T>(`${this.url}`, value);
  }

  edit(value: T): Observable<T> {
    return this._http.patch<T>(`${this.url}/${value.id}`, value);
  }

  delete(id: number) {
    return this._http.delete(`${this.url}/${id}`);
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get count$() {
    return this.count.asObservable();
  }

  get queryParams$() {
    return this.queryParams.asObservable();
  }

  getParams(queryParams: QueryParams) {
    const pageSize = queryParams ? queryParams.pageSize.toString() : '10';
    const page = queryParams ? queryParams.page.toString() : '1';
    return {
      ...queryParams,
      pageSize,
      page
    };
  }
}
